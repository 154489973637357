@import url(https://fonts.googleapis.com/css2?family=Kumbh+Sans:wght@300;400;700&family=Lato:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap);
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

html,
body,
.App,
#root {
    height: 100%;
    padding: 0px;
    overflow: hidden;
    margin: 0;
    background: #cfcfcf;
}

.App {
    box-sizing: border-box;
    padding: 20px;
    height: 100%;
    height: -webkit-fill-available;
    min-height: -moz-available;
}

#outerContainer {
    height: 100%;
    width: calc(100vw - 40px);
    /* height: -webkit-fill-available; */
    /* top:-50px; */
    /* margin: 20px; */
    position: relative;

    box-shadow: -6px -6px 8px #f0f0f0, 6px 6px 8px rgba(121, 123, 129, 0.5),
        inset 4px 4px 15px rgba(14, 17, 16, 0.5),
        inset -4px -4px 15px rgba(94, 94, 94, 0.5);
    border-radius: 20px;
    background: #252525;
}

#dragPalette,
#gradientPalette {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    touch-action: none;
    border-radius: 20px;
    touch-action: none;
}

.dragItem {
    -webkit-tap-highlight-color: transparent;
    box-sizing: border-box;
    z-index: 1;
    touch-action: none;
    width: 26px;
    height: 26px;
    border: 3px solid #ffffff;
    border-radius: 50%;
    touch-action: none;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    transition: all 0.2s cubic-bezier(0.39, 0.58, 0.57, 1),
        background-color 0.3s cubic-bezier(1, -0.01, 0.95, -0.11);
    position: absolute;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}

.dragIWeight {
    box-sizing: border-box;
    z-index: 1;
    touch-action: none;
    background-color: #ffffff80;
    border-radius: 50%;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    position: absolute;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}

.dragColor {
    box-sizing: border-box;
    z-index: 1;
    touch-action: none;
    background-color: #ffffff;
    border-radius: 50%;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    position: absolute;
    box-shadow: 0px 0px 4px rgba(53, 53, 53, 0.5);

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    text-align: center;
}

.dragColor:active {
    box-shadow: none;
}

@media (hover: hover) and (pointer: fine) {
    .dragItem:hover {
        width: 35px;
        height: 35px;
        cursor: pointer;
        border-width: 8px;
    }
}

.dragItem.active {
    width: 35px;
    height: 35px;
    cursor: pointer;
    border-width: 8px;
    box-shadow: 0px 0px 4px rgba(53, 53, 53, 0.5);
}

.dragItem:active {
    width: 35px;
    height: 35px;
    cursor: pointer;
    border-width: 8px;
}

.dragIContainer {
    position: absolute;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: flex-start;
}

.dragIContainer .sketch-picker {
    -webkit-transform: translate(calc(-50% + 18px), +10px);
            transform: translate(calc(-50% + 18px), +10px);
    z-index: 3;
}

#point-manager {
    z-index: 0;
    position: absolute;
    top: calc(0% + 20px);
    right: calc(0% + 20px);

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
    align-items: center;
    align-content: stretch;

    overflow: hidden;
    touch-action: none;
}
#undo {
    bottom: calc(0% + 20px);
    left: calc(0% + 20px);
}
#redo {
    bottom: calc(0% + 20px);
    right: calc(0% + 20px);
}

.undo-redo {
    padding: 10px;
    z-index: 0;
    position: absolute;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
    align-items: center;
    align-content: stretch;

    transition: all 0.2s cubic-bezier(0.39, 0.58, 0.57, 1);

    overflow: hidden;
    touch-action: none;
}

#point-manager button {
    margin: 10px;
    height: 40px;
    width: 40px;

    font-size: 20px;
    font-family: "Inter", sans-serif;
    font-weight: 900;
    color: rgb(211, 211, 211);

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    text-align: center;

    background: #252525;

    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.6),
        -2px -2px 8px rgba(133, 129, 129, 0.5),
        inset -3px -3px 4px rgba(19, 20, 20, 0.5),
        inset 2px 2px 4px rgba(106, 107, 109, 0.5);

    border: none;
    border-radius: 6px;
    padding: 0 10px 0 10px;
}

.undo-redo button {
    height: 40px;
    width: 40px;

    font-size: 20px;
    font-family: "Inter", sans-serif;
    font-weight: 900;
    color: #ffffff;

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    text-align: center;

    background-color: #0000001c;
    border: 2px solid #ffffff98;
    border-radius: 6px;
    padding: 0 10px 0 10px;
}

.hideButton.hidden {
    opacity: 0%;
}

@media (hover: hover) and (pointer: fine) {
    #point-manager button:hover {
        box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.6),
            -2px -2px 8px rgba(133, 129, 129, 0.5),
            inset -1px -1px 4px rgba(19, 20, 20, 0.5),
            inset 1px 1px 4px rgba(106, 107, 109, 0.5);
    }
    #point-manager button:active {
        box-shadow: 2px 2px 8px rgba(11, 11, 11, 0.6),
            -2px -2px 8px rgba(101, 101, 101, 0.5),
            inset 6px 6px 10px rgba(28, 29, 29, 0.5),
            inset -5px -5px 10px rgba(45, 45, 47, 0.5);
    }
}

@media (hover: none) and (pointer: coarse) {
    #point-manager button:active {
        box-shadow: 2px 2px 8px rgba(11, 11, 11, 0.6),
            -2px -2px 8px rgba(101, 101, 101, 0.5),
            inset 6px 6px 10px rgba(28, 29, 29, 0.5),
            inset -5px -5px 10px rgba(45, 45, 47, 0.5);
    }
}

